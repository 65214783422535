import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CreateIcon from '@material-ui/icons/Create';
import Button from '@material-ui/core/Button';

import ShowFiles from '../updateGrRecord/ShowFiles';
import DropFilesAttach from './files/DropFilesAttach';
import {
  presentDate,
  presentFormatedData,
} from '../functionalComponents/dataFormatFunctions';

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 500,
    fontSize: '1.5rem',
    textAlign: 'left',
    paddingBottom: '0.3rem',
  },
  font: {
    fontWeight: 500,
    // padding: '0.5rem 0.5rem 0.5rem 0rem',
    textAlign: 'initial',
  },
  fontPadding: {
    // padding: '0.5rem 0.5rem 0.5rem 0rem',
    textAlign: 'initial',
    overflow: 'auto',
  },
  button: {
    backgroundColor: 'transparent',
    textAlign: 'end',
    paddingTop: '1rem',
  },
  borders: {
    border: '2px solid #707070',
    display: 'flex',
    width: '1.6rem',
    backgroundColor: 'grey',
  },

  grid: {
    paddingBottom: '2rem',
    paddingTop: '1rem',
    paddingRight: '1.5rem',
  },
}));

export default function QualityAssurance(props) {
  const classes = useStyles();
  const {
    errors,
    qualityAssuranceInfo,
    isAddRecord,
    handleOpenDialog,
    // COAFiles,
    POLineRecordDetails,
  } = props;

  const QAValidations = useSelector((state) => {
    if (state.pcmp.common.reports.rmpmGrnFeatures?.status) {
      return state.pcmp.common.reports.rmpmGrnFeatures.data.validations.QAInfo;
    }
    return {};
  });

  const [COAFiles, setCOAFILES] = React.useState(qualityAssuranceInfo.COAFiles);

  const handlingDeleteFile = (i) => {
    // for rec fromBE=> mfd as 1, for local filter out
    //check for filetype in file
    //if 'buffer' then follow filter logic
    // if it is not buffer edit that file data sayn mfd 1
    if (COAFiles[i].grFileType === 'buffer') {
      let newdel = COAFiles.filter(
        (file) => file.grFileName !== COAFiles[i].grFileName
      );
      setCOAFILES(newdel);
    } else {
      // COAFiles[i].markForDelete=1;
      let markDelFile = COAFiles.map((file) =>
        file.grFileHash === COAFiles[i].grFileHash
          ? { ...file, markForDelete: true }
          : file
      );
      setCOAFILES(markDelFile);
    }
  };

  const fileHandling = (files) => {
    files.forEach((file) => {
      const reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = () => {
        // Do whatever you want with the file contents
        const newArr = {
          grFileData: reader.result,
          grFileName: file.name,
          grFileType: 'buffer',
          schrockenGRFileId: '',
          grFileHash: '',
          markForDelete: false,
        };

        props.handlingFiles(newArr, 'COAFiles');
      };
    });
  };
  return (
    <React.Fragment>
      <Grid container className={classes.child}>
        <Grid item xs={9} sm={6} md={6} className={classes.grid}>
          <Typography variant='h4' className={classes.title}>
            Quality Assurance Information
          </Typography>
          <span className={classes.borders}></span>
        </Grid>

        {props.isEditExist === true ? (
          <Grid item xs={2} sm={6} md={6} className={classes.button}>
            <Button
              variant='contained'
              startIcon={<CreateIcon fontSize='small' />}
              style={{
                backgroundColor: 'transparent',
                boxShadow: 'none',
                border: '1px solid #000000de',
              }}
              size='small'
              onClick={() => handleOpenDialog('openQuality')}
            >
              Edit
            </Button>
          </Grid>
        ) : (
          <Grid item xs={2} sm={6} md={6}></Grid>
        )}

        <Grid item xs={12} sm={6} md={3} className={classes.grid}>
          <Typography variant='body2' className={classes.font}>
            QA Done
          </Typography>
          <Typography
            variant='body2'
            className={classes.fontPadding}
            color={
              qualityAssuranceInfo.isQACompleted === false ? 'error' : 'inherit'
            }
          >
            {qualityAssuranceInfo.isQACompleted === false
              ? 'No, QA is Not Done'
              : 'Yes, QA is Done'}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6} md={3} className={classes.grid}>
          <Typography variant='body2' className={classes.font}>
            Quantity used for QA Sampling
          </Typography>

          <Typography
            variant='body2'
            className={classes.fontPadding}
            color={
              errors.quantityQA ||
              presentFormatedData(qualityAssuranceInfo.samplingQuantityQA) ===
                '-'
                ? 'error'
                : 'inherit'
            }
          >
            {presentFormatedData(
              qualityAssuranceInfo.samplingQuantityQA,
              '-Not provided-'
            )}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6} md={3} className={classes.grid}>
          <Typography variant='body2' className={classes.font}>
            Unit of Measurement (UOM)
          </Typography>

          <Typography
            variant='body2'
            className={classes.fontPadding}
            color={errors.unitOfMeasurementQA ? 'error' : 'inherit'}
          >
            {presentFormatedData(
              // POLineRecordDetails.unitOfMeasureSKU,
              qualityAssuranceInfo.unitOfMeasurementQA,
              '-Not provided-'
            )}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6} md={3} className={classes.grid}>
          <Typography variant='body2' className={classes.font}>
            Analytical Report (AR) Number
          </Typography>

          <Typography
            variant='body2'
            className={classes.fontPadding}
            color={
              errors.analyticalReportNumber ||
              presentFormatedData(
                qualityAssuranceInfo.analyticalReportNumber
              ) === '-'
                ? 'error'
                : 'inherit'
            }
          >
            {presentFormatedData(
              qualityAssuranceInfo.analyticalReportNumber,
              '-Not provided-'
            )}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6} md={3} className={classes.grid}>
          <Typography variant='body2' className={classes.font}>
            Analytical Report (AR) Date
          </Typography>

          <Typography
            variant='body2'
            className={classes.fontPadding}
            // color={errors.analyticalReportDate ? 'error' : 'inherit'}
          >
            {presentDate(
              qualityAssuranceInfo.analyticalReportDate,
              '-Not provided-'
            )}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6} md={3} className={classes.grid}>
          <Typography variant='body2' className={classes.font}>
            Manufacturing Date
          </Typography>

          {qualityAssuranceInfo.materialManufactureDate === null ? (
            <Typography
              variant='body2'
              className={classes.fontPadding}
              color={
                QAValidations[
                  POLineRecordDetails.materialType === 'ROH' ? 'RM' : 'PM'
                ].mfgDate.required
                  ? 'error'
                  : 'inherit'
              }
            >
              -Not provided-
            </Typography>
          ) : (
            <Typography
              variant='body2'
              className={classes.fontPadding}
              color={errors.materialManufactureDate ? 'error' : 'inherit'}
            >
              {presentDate(qualityAssuranceInfo.materialManufactureDate)}
            </Typography>
          )}
        </Grid>

        <Grid item xs={12} sm={6} md={3} className={classes.grid}>
          <Typography variant='body2' className={classes.font}>
            Expiration Date
          </Typography>
          {qualityAssuranceInfo.expirationDate === null ? (
            <Typography
              variant='body2'
              className={classes.fontPadding}
              color={
                QAValidations[
                  POLineRecordDetails.materialType === 'ROH' ? 'RM' : 'PM'
                ].mfgDate.required
                  ? 'error'
                  : 'initial'
              }
            >
              -Not provided-
            </Typography>
          ) : (
            <Typography
              variant='body2'
              className={classes.fontPadding}
              color={errors.expirationDate ? 'error' : 'inherit'}
            >
              {presentDate(qualityAssuranceInfo.expirationDate)}
            </Typography>
          )}
        </Grid>

        <Grid item xs={12} sm={6} md={3} className={classes.grid}>
          <Typography variant='body2' className={classes.font}>
            Batch Number
          </Typography>

          <Typography
            variant='body2'
            color={
              POLineRecordDetails.materialType === 'ROH' &&
              presentFormatedData(qualityAssuranceInfo.batchNumberQA) === '-'
                ? 'error'
                : 'inherit'
            }
            className={classes.fontPadding}
          >
            {presentFormatedData(
              qualityAssuranceInfo.batchNumberQA,
              '-Not provided-'
            )}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6} md={3} className={classes.grid}>
          <Typography variant='body2' className={classes.font}>
            QC Sampling Material Number (optional)
          </Typography>

          <Typography variant='body2' className={classes.fontPadding}>
            {presentFormatedData(
              qualityAssuranceInfo.samplingMaterialNumberQA,
              '-Not provided-'
            )}
          </Typography>
        </Grid>

        {localStorage.collabType === 'CMO' &&
          (props.status === 3 || props.status === 13) && (
            <Grid container justify='center' style={{ paddingBottom: '2rem' }}>
              <Grid item xs={12} sm={12} md={12}>
                <DropFilesAttach
                  title='Upload Certificates of Analysis (CoA)'
                  handleUploadFiles={fileHandling}
                />
              </Grid>
            </Grid>
          )}

        <Grid
          container
          className={classes.padding}
          style={{ paddingBottom: '1rem' }}
        >
          <Grid item xs={12} sm={6} md={6}>
            <Typography
              variant='body2'
              className={classes.font}
              style={{ paddingBottom: '0.5rem' }}
            >
              Uploaded Document List
            </Typography>

            <ShowFiles
              files={isAddRecord ? COAFiles : qualityAssuranceInfo.COAFiles}
              handlingDeleteFile={
                isAddRecord
                  ? handlingDeleteFile
                  : props.parentHandlingDeleteFile
              }
              name='COAFiles'
              isUploadFile={
                (props.status === 3 || props.status === 13) &&
                localStorage.collabType === 'CMO' &&
                true
              }
              filesRequired={QAValidations.coaFile?.required}
            />
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
