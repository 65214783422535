import React, { useEffect, useState } from 'react';
import {
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
  TextField,
  Checkbox,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import SearchIcon from '@material-ui/icons/Search';
import { useDispatch, useSelector } from 'react-redux';
import { fetchWarehouses } from '../../../containers/actions/pharmaCo/manageUsersActions';

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

const AssociateWarehouse = ({
  selectedWarehouses,
  handleChangeWarehouseOrCMOList,
}) => {
  const dispatch = useDispatch();
  const [accessType, setAccessType] = useState('select'); // Manage radio selection

  // Example CMO list
  // const warehousesList = [
  //   {
  //     warehouseName: 'Malik Life Sciences Pvt Ltd',
  //     warehouseCode: 'L731',
  //   },
  //   {
  //     warehouseName: 'Zen Pharma (P) Ltd',
  //     warehouseCode: 'L728',
  //   },
  //   {
  //     warehouseName: 'Zeiss Pharmaceuticals Pvt. Ltd.',
  //     warehouseCode: 'L749',
  //   },
  //   {
  //     warehouseName: 'Zeiss Pharma Limited (Unit-I)',
  //     warehouseCode: 'L762',
  //   },
  //   {
  //     warehouseName: 'Midas-Care Pharma Pvt. Ltd',
  //     warehouseCode: 'L719',
  //   },
  // ];

  let warehousesList = useSelector((state) =>
    state.pcmp.pharmaCo.manageUsers.warehousesList?.data?.warehouseDetailsList
      ? state.pcmp.pharmaCo.manageUsers.warehousesList.data.warehouseDetailsList
      : []
  );

  useEffect(() => {
    dispatch(fetchWarehouses());
  }, [dispatch]);

  // Handle radio selection
  const handleAccessChange = (event) => {
    const value = event.target.value;

    // Automatically populate CMOs for "Access all CMOs"
    if (value === 'all') {
      handleChangeWarehouseOrCMOList('selectedWarehouses', warehousesList);
    } else {
      handleChangeWarehouseOrCMOList('selectedWarehouses', []); // Clear CMOs for "Access select CMOs"
    }
    setAccessType(value);
  };

  const handleSelectionChange = (event, value) => {
    if (
      selectedWarehouses.filter(
        (val) =>
          val.warehouseName === value[0].warehouseName &&
          val.warehouseCode === value[0].warehouseCode
      ).length === 0
    ) {
      handleChangeWarehouseOrCMOList(
        'selectedWarehouses',
        selectedWarehouses.concat(value)
      );
    } else {
      handleChangeWarehouseOrCMOList(
        'selectedWarehouses',
        selectedWarehouses.filter(
          (val) =>
            !(
              val.warehouseName === value[0].warehouseName &&
              val.warehouseCode === value[0].warehouseCode
            )
        )
      );
    }
  };

  return (
    <Box>
      {/* Heading */}
      <Typography variant='h6' gutterBottom>
        Associate warehouses
      </Typography>

      {/* Radio Buttons */}
      <FormControl component='fieldset' style={{ marginBottom: '1rem' }}>
        <RadioGroup value={accessType} onChange={handleAccessChange}>
          <FormControlLabel
            value='none'
            control={<Radio color='primary' />}
            label='None'
          />
          <FormControlLabel
            value='all'
            control={<Radio color='primary' />}
            label={`Access all (${warehousesList.length} warehouses)`}
          />
          <FormControlLabel
            value='select'
            control={<Radio color='primary' />}
            label='Access select warehouses'
          />
        </RadioGroup>
      </FormControl>

      {/* Autocomplete with checkbox for multiple selections */}
      {accessType === 'select' && (
        <Autocomplete
          multiple
          id='checkboxes-tags-demo'
          options={warehousesList}
          disableCloseOnSelect
          value={[]}
          onChange={handleSelectionChange}
          getOptionLabel={(option) =>
            `${option.warehouseName} - ${option.warehouseCode}`
          }
          renderOption={(option) => {
            let selected =
              selectedWarehouses.filter(
                (val) =>
                  val.warehouseName === option.warehouseName &&
                  val.warehouseCode === option.warehouseCode
              ).length > 0;
            return (
              <React.Fragment>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.warehouseName} - {option.warehouseCode}
              </React.Fragment>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant='outlined'
              InputProps={{
                ...params.InputProps,
                startAdornment: <SearchIcon />,
              }}
              // label='Select Options'
              placeholder='Start typing to search and select'
            />
          )}
        />
      )}

      {/* Display selected CMOs */}
      <Box mt={2}>
        <Typography variant='body1' style={{ marginBottom: '0.5rem' }}>
          Your selections:
        </Typography>
        <Box
          style={{
            border: '1px solid #ccc',
            borderRadius: '4px',
            padding: '1rem',
            maxHeight: '150px',
            overflowY: 'auto', // Enable vertical scroll when selections exceed box height
            display: 'flex',
            flexDirection: 'column', // Stack items vertically
          }}
        >
          {selectedWarehouses.length > 0 ? (
            selectedWarehouses.map((val, index) => (
              <Typography
                key={index}
                variant='body2'
                style={{ fontSize: '14px' }}
              >
                {val.warehouseName} - {val.warehouseCode}
              </Typography>
            ))
          ) : (
            <Typography variant='body2' style={{ color: '#aaa' }}>
              No selections available.
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default AssociateWarehouse;
