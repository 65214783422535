// Pharma Co Dashboard
export const PO_STATS = 'po_stats';
export const CMO_STATS = 'cmo_stats';
export const SHIPMENT_STATS = 'shipment_stats';
export const PO_DATA = 'po_data';

// Pharma Co Purchase Orders
export const PHARMA_CO_CMO_STATS = 'pharma_co_cmo_stats';
export const MILESTONE_CONFIG_TEMPLATES = 'milestone_config_templates';
export const MILESTONE_CONFIG_TEMPLATE_DATA = 'milestone_config_template_data';
export const PO_PREVIEW = 'po_preview';
export const PO_ADD = 'po_add';

// Manage Pharma Co and CMS Members
export const USERS_DETAILS = 'users_details';
export const PCMP_COLLABORATORS = 'pcmp_collaborators';
export const PCMP_ROLES = 'pcmp_roles';
export const ADD_PCMP_USER = 'add_pcmp_user';
export const REMOVE_PCMP_USER = 'remove_pcmp_user';
export const SEND_INVITES = 'send_invites';

export const PO_LIST = 'po_list';
export const CMO_MEMBERS_LIST = 'cmo_members_list';
export const CMO_TYPES_LIST = 'cmo_types_list';
export const PO_DETAILS = 'po_details';
export const PO_STATUS = 'po_status';
export const LINE_ITEMS = 'line_items';
export const BATCH_ITEMS = 'batch_items';
export const PO_LINE_ITEMS = 'po_line_items';
export const PO_LINE_ITEM_DETAILS = 'po_line_item_details';
export const LINE_ITEM_STATUS_LIST = 'line_item_status_list';
export const UPDATE_LINE_STATUS = 'update_line_status';
export const UPDATE_LINE_STATUS_FAIL = 'update_line_status_fail';

// Pharma Co Batch Actions
export const PHARMA_LINE_BATCH_ITEMS = 'pharmaco_line_batch_items';
export const PHARMA_BATCH_UPDATE = 'pharma_batch_update';
export const PHARMA_FETCH_RM_AVAILABILITY = 'pharmaco_fetch_rm_availability';
export const PHARMA_FETCH_PM_AVAILABILITY = 'pharmaco_fetch_pm_availability';

// Pharma file
export const PHARMA_UPLOAD_GRN_FILE = 'pharma_upload_grn';
export const PHARMA_DOWNLOAD_INVOICE_FILE = 'pharma_download_invoice';
export const PHARMA_DOWNLOAD_COA_FILE = 'pharma_download_coa';
export const PHARMA_DOWNLOAD_RconSheet_FILE = 'pharma_download_reconsheet';
export const PHARMA_DOWNLOAD_PIR_FILE = 'pharma_download_pir';
export const PHARMA_DOWNLOAD_ShipperRecord_FILE = 'pharma_download_srf';
export const PHARMA_DOWNLOAD_EWAY_FILE = 'pharma_download_ewayfile';
export const PHARMA_DOWNLOAD_LR_FILE = 'pharma_download_lrfile';

// CMO Types
export const CMO_PO_STATS = 'cmo_po_stats';
export const CMO_FACILITIES_STATS = 'cmo_facilits_stats';
export const CMO_RMPM_STATS = 'cmo_rmpm_stats';
export const CMO_USER_DETAILS = 'cmo_user_details';
export const CMO_RECENT_PO_UPDATES = 'cmo_recent_po_updates';
export const CMO_RECENT_LINEITEMS_UPDATES = 'cmo_recent_lineitems_updates';

//CMO ShortFalls Types
export const CMO_SHORTFALLS_ITEMS = ' cmo_shortfalls_items';
export const CMO_ADD_SHORTFALLS = 'cmo_add_shortfalls';
export const CMO_ADD_GRNRECEIPT = 'cmo_add_grnreceipt';
export const CMO_ADD_GRNCSV = 'cmo_add_grnCSV';
export const CMO_FETCH_GRNRECEIPTS = 'cmo_fetch_grnreceipts';

// CMO Batch action types
export const CMO_LINE_BATCH_ITEMS = ' cmo_line_batch_items';
export const CMO_ADD_BATCH_ITEM = 'cmo_add_batch_item';
export const CMO_ADD_BATCH_WARNING = 'cmo_add_batch_warning';
export const CMO_BATCH_ITEM_DATES = 'cmo_batch_item_dates';
export const CMO_BATCH_STATUS_LIST = 'cmo_batch_status_list';
export const CMO_BATCH_UPDATE = 'cmo_batch_update';

export const CMO_ADD_SASL_BATCH_ITEM = 'cmo_add_sasl_batch_item';
export const SEND_SASL_BATCH_APPROVAL = 'send_sasl_batch_approval';

export const CMO_ADD_RM_AVAILABILITY = 'cmo_add_rm_availability';
export const CMO_FETCH_RM_AVAILABILITY = 'cmo_fetch_rm_availability';
export const CMO_RM_STATUS_LIST = 'cmo_rm_status_list';
export const CMO_UPDATE_RM = 'cmo_update_rm';

export const CMO_ADD_PM_AVAILABILITY = 'cmo_add_pm_availability';
export const CMO_FETCH_PM_AVAILABILITY = 'cmo_fetch_pm_availability';
export const CMO_FETCH_PM_STATUSES = 'cmo_fetch_pm_statuses';
export const CMO_UPDATE_PM = 'cmo_update_pm';

// CMO File Actions
export const CMO_UPLOAD_QC_FILE = 'cmo_upload_qc_file';
export const CMO_UPLOAD_INVOICE_FILE = 'cmo_upload_invoice_file';
export const CMO_DOWNLOAD_GRN_FILE = 'cmo_download_grn_file';

//V1.3 DASHBOARD
export const BATCH_SUMMERY = 'batch_summery';
export const LINE_SUMMERY = 'line_summery';
export const SUMMERY = 'summery';
export const BATCH_SUMMERY_DETAILS = 'batch_summery_details';
export const LINE_SUMMERY_DETAILS = 'line_summery_details';
export const ALL_PURCHASE_ORDERS = 'all_purchase_orders';
export const ALL_LINE_ITEMS = 'all_line_items';
export const ALL_BATCH_ITEMS = 'all_batch_items';
export const PO_BATCH_ITEMS = 'po_batch_items';

//v1.4 Reports
export const YIELD_REPORT = 'yield_report';
export const AUDIT_REPORT = 'audit_report';
export const LINE_AUDIT_REPORT = 'line_audit_report';
export const OTIF_REPORT = 'otif_report';
export const PO_LINE_NUMBERS = 'po_line_numbers';
export const PO_LINE_BATCH_NUMBERS = 'po_batch_numbers';
export const BATCH_AUDIT_REPORT = 'batch_audit_report';
export const PO_NUMBER_ERROR = 'po_no_error';

// COMMON ACTIONS
export const CUSTOM_FILES = 'custom_files';
export const CUSTOM_DOWNLOADED_FILE = 'custom_downloaded_file';

//release/1.5 executive dashbard
export const EXEC_CARDS = 'exec_cards';
export const EXEC_LINES_OVERVIEW = 'exec_lines_overview';
export const EXEC_LINES_CMO = 'exec_lines_cmo';
export const EXEC_LINE_ITEMS = 'exec_line_items';
export const EXEC_BATCH_ITEMS = 'exec_batch_items';
export const ON_TRACK_CMO = 'on_track_cmo';

//Settings
export const DATE_FORMAT_UPDATE = 'date_format_update';

//release/v2.0  QUICK VIEW
export const QUICK_VIEW = 'quick_view';
// Add GRN fetch
export const ADD_GRN_FETCHTABLE = 'addGRNFetchTable';
export const ADD_GRN_MODIFIED_FETCHTABLE = 'addGRNModifiedFetchTable';
export const OFFSET_ADD_GRN_FETCHTABLE = 'offsetAddGRNFetchTable';
// Fetch SASL Table
export const FETCH_SASL_TABLE = 'fetchSaslTable';
// Update GRN
export const UPDATE_GRN_LINE_DATA = 'updateGRNLineData';
export const GRN_STATUSES = 'grn_statuses';
export const GRN_FEATURES = 'rmpm_grn_features';
//fetch UOM
export const FETCH_UOM = 'fetchUOM';
// apprve GRN
export const APPROVE_GRN_DATA = 'approveGRNData';
//test backdrop 2.0
export const OPEN_BACKDROP = 'open_backdrop';
export const DESTROY_BACKDROP = 'destroy_backdrop';
// CMO list v2.0.1
export const CMO_LIST = 'cmo_list';

export const UPLOAD_GRN_FILE = 'upload_GRN_File';
export const UPDATE_GR_RECORD = 'update_GR_Record';
// Record Timeline
export const RECORD_TIMELINE = 'record_timeline';
//Record Resolutions
export const RECORD_RESOLUTION = 'record_resolution';
//Export To Excel
export const EXPORT_TO_EXCEL = 'export_to_excel';
// Download GR ZIP
export const DOWNLOAD_GR_ZIP = 'download_gr_zip';

export const DOWNLOAD_EXCEL_FILE = 'download_excel_file';
// Access control
export const SCREENS_ACCESS = 'screens_access';
export const FEATURES_ACCESS = 'features_access';
// Delete GR Record
export const DELETE_GR = 'delete_gr';
//5% tolerance quantity(Actual Quantity Received) - type is needed to show popup in FE based on Response
export const QUANTITY_TOLERANCE_ALERT = 'quantity_tolerance_alert';

// release/v2.1 code
export const VIEW_SL_DETAILS = 'view_sl_details';
export const FETCH_BOM_DETAILS = 'fetch_bom_details';
export const FETCH_BATCH_DETAILS = 'fetch_batch_details';
export const FETCH_BOMCONSUMPTION_INFO = 'fetch_bom_consumption_info';
export const FETCH_DELIVERY_SCHEDULE_BATCH_DETAILS =
  'fetch_delivery_schedule_batch_details';
export const SASL_BATCH_UPDATE = 'sasl_batch_update';
export const UPDATE_BOM_CONSUMPTION_DETAILS = 'update_bom_consumption_details';
export const NEXT_STATUS_LIST = 'next_status_list';
export const DS_HEADER = 'ds_header';
export const ALL_DS_STATUS = 'all_ds_status';
export const SASL_BATCH_AUDIT_REPORT = 'sasl_batch_audit_report';
export const FETCH_SL_LINES = 'fetch_sl_lines';
export const FETCH_DS = 'fetch_ds';
export const SASL_DS_AUDIT_REPORT = 'sasl_ds_audit_report';

export const SASL_BATCH_APPROVE = 'sasl_batch_approve';
// load table indicator
export const TABLE_LOAD = 'table_load';
export const DESTROY_TABLE_LOAD = 'destroy_table_load';
// User Deactivation

export const USER_DEACTIVATION = 'user_deactivation';
export const UPLOAD_SASL_BATCHDATAFROMFILE = 'upload_SaSl_BatchDataFromFile';
export const UPDATE_SASL_BATCHDATAFROMFILE = 'update_SaSl_BatchDataFromFile';
export const UPLOAD_SASL_CONSUMPTIONINFOFROMFILE =
  'upload_SaSl_ConsumptionInfoFromFile';
export const UPDATE_SASL_CONSUMPTIONINFOFROMFILE =
  'update_SaSl_ConsumptionInfoFromFile';
export const REJECT_SASL_BATCH_APPROVAL = 'reject_SaSl_Batch_Approval';
export const SA_AUDIT_REPORT = 'sa_audit_report';
export const SL_AUDIT_REPORT = 'sl_audit_report';

// Loading state for Tables
export const OPEN_TABLE_LOADING = 'open_table_loading';
export const CLOSE_TABLE_LOADING = 'close_table_loading';
export const FETCH_THRESHOLD_DETAILS = 'fetch_threshold_details';

// Batches state for Table
export const FETCH_DELIVERY_SCHEDULE_BATCHES =
  'fetch_delivery_schedule_batches';
export const QUICK_SUMMARY_STATUSES = 'quick_summary_status';
export const CMO_MEMBERS_WITH_ID = 'cmo_members_with_id';
export const FETCH_BATCH_PRODUCTS = 'fetch_batch_products';
export const BATCH_UPDATED = 'batch_updated';

// release/v2.3 code
export const ALL_QA_STATUS = 'all_qa_status';
export const QA_APPROVAL = 'qa_approval';

// screen Filters
export const SASL_FILTERS = 'sasl_filters';
export const DS_FILTERS = 'ds_filters';
export const BATCH_FILTERS = 'batch_filters';
//Send CoA File Reminder
export const SEND_COA_FILE_REMINDER = 'send_coa_file_reminder';

//get warehouse
export const FETCH_WAREHOUSES = 'fetch_warehouses';
