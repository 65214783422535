import { setAuthToken } from '../../utils/setAuthToken';

export const redirectUser = (response, history, sessionLogin) => {
  if (response.data.authToken) {
    // Store token in local storage
    localStorage.setItem('authToken', response.data.authToken);
    // Assign AUTH TOKEN

    setAuthToken(response.data.authToken);
  }
  localStorage.setItem('accountType', response.data.accountType);
  localStorage.setItem('userType', response.data.userType);
  let user = response.data.userType;
  // check whether two step is completed
  if (
    response.data.isTwoStepAuthenticationEnabled === true &&
    response.data.isTwoStepAuthenticationCompleted === false
  ) {
    history.push('/twostepauth');
  } else if (user === 'schrockenAdmin') {
    // Redirect to main dashboard
    history.push(`/${user}/dashboard`);
  } else {
    // Save ecosystem details
    localStorage.setItem('collabType', response.data.collabType);
    localStorage.setItem('organizationName', response.data.organizationName);
    localStorage.setItem('ecosystemId', response.data.ecosystemId);
    localStorage.setItem('roleName', response.data.roleName);
    // Change these values later to dynamic
    localStorage.setItem('appName', response.data.appName);
    localStorage.setItem('network', response.data.network);
    // if (sessionLogin) {
    //   history.goBack();
    // } else {
    // history.push(
    //   `/pcmp/mosymphony/useraccess?role=${response.data.roleName}`
    // );
    history.push('/switch-ecosystem');
    // }
  }
};
