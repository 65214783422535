import React from 'react';

// import StyledButton from './Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
// Icons
import CloseIcon from '@material-ui/icons/Close';

import { makeStyles } from '@material-ui/core';
import StyledButton from '../../models/Button';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

const useStyles = makeStyles({
  closeText: {
    fontSize: '1rem',
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    padding: '0.5rem',
    '&:hover': {
      borderBottom: '0.5px solid red',
      color: '#e36e39',
    },
  },
  // closeText: {
  //   fontSize: '1rem',
  //   fontWeight: 500,
  //   display: 'flex',
  //   alignItems: 'center',
  //   cursor: 'pointer',
  //   padding: '1rem',
  //   '&:hover': {
  //     borderBottom: '0.5px solid red',
  //     color: '#e36e39',
  //   },
  // },
  saved: {
    textAlign: 'center',
    padding: '0.5rem',
    fontWeight: 500,
    fontSize: '1.5rem',
  },
  text: {
    textAlign: 'center',
    fontSize: '1rem',
    margin: '1rem',
    fontWeight: 500,
  },
  iconGrid: {
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    fontSize: '5rem',
    color: '#39811D',
  },
  cardContent: {
    padding: '2rem !important',
  },
  buttonGrid: {
    textAlign: 'center',
    padding: '0.5rem',
  },
  noButton: {
    backgroundColor: 'transparent',
    color: 'black',
    border: '1px solid black',
    paddingLeft: '3rem',
    paddingRight: '3rem',
    textTransform: 'none',
    borderRadius: '2rem',
    '&:hover': {
      backgroundColor: 'black',
      color: 'white',
      border: '1px solid #FFF',
    },
  },
});

const ResolveBatchAndQaDialog = ({ children, ...props }) => {
  const classes = useStyles();
  return (
    <DialogContent
      classes={{
        root: classes.cardContent,
      }}
    >
      <Grid container justify='flex-end'>
        <Grid item xs={12} sm={9} md={9}>
          <Typography variant='h5' className={classes.saved}>
            {props.title}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={2} md={2} style={{ marginLeft: '-1.3rem' }}>
          <Typography
            variant='body2'
            className={classes.closeText}
            onClick={props.handleClose}
          >
            Close <CloseIcon fontSize='large' />
          </Typography>
        </Grid>
      </Grid>

      <Typography variant='body2' className={classes.text}>
        {props.subtitle}
      </Typography>

      {children}
      <Grid
        item
        xs={12}
        className={classes.buttonGrid}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          paddingTop: '2rem',
        }}
      >
        <StyledButton
          style={{ paddingLeft: '3rem', paddingRight: '3rem' }}
          onClick={() => props.handleOpen('GRN_Resolve')}
          disabled={props.isButtonDisabled ? props.isButtonDisabled : false}
          endIcon={
            <ArrowForwardIosIcon
              style={{ fontSize: props.size === 'small' ? 12 : 15 }}
            />
          }
        >
          Resolve GRN Issue
        </StyledButton>
        <StyledButton
          style={{ paddingLeft: '3rem', paddingRight: '3rem' }}
          onClick={() => props.handleOpen('CoA_Resolve')}
          disabled={props.isButtonDisabled ? props.isButtonDisabled : false}
          endIcon={
            <ArrowForwardIosIcon
              style={{ fontSize: props.size === 'small' ? 12 : 15 }}
            />
          }
        >
          Resolve CoA Issue
        </StyledButton>
      </Grid>
    </DialogContent>
  );
};

export default ResolveBatchAndQaDialog;
